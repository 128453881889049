/*import { Flex, Heading, Text, VStack, HStack, Box } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/react";
import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { default as NextImage } from "next/image";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
const ResetPasswordModalDynamic = dynamic(() =>
  import("src/components/auth/ResetPasswordModal")
);
const SignUpStepsVerticalDynamic = dynamic(() =>
  import("src/components/layout/svg/SignUpStepsVertical")
);
const SignUpStepsHorizontalDynamic = dynamic(() =>
  import("src/components/layout/svg/SignUpStepsHorizontal")
);
import { useEffect } from "react";
import { createStandaloneToast } from "@chakra-ui/react";
import supabase from "src/lib/utils/supabase/init";
import PlanAdCard from "src/components/pages/index/PlanAdCard";
import FeaturesBlock from "src/components/pages/index/FeaturesBlock";
import styles from "src/theme/css/index.module.css";

export const getStaticProps = async () => {
  // TODO: may have to make this an API route that receives
  //       a webhook from Chargebee upon user sign-up

  const { error: cbCountErr, count: cbCount } = await supabase
    .from("subscriptions")
    .select("plan_id", { count: "exact" })
    .or(
      "plan_id.eq.cash-buyer-monthly,plan_id.eq.cash-buyer-annual,plan_id.eq.wholesaler-&-cash-buyer-annual,plan_id.eq.wholesaler-&-cash-buyer-monthly"
    );

  if (cbCountErr) {
    console.log(cbCountErr);
  }

  const { error: wsCountErr, count: wsCount } = await supabase
    .from("subscriptions")
    .select("plan_id", { count: "exact" })
    .or(
      "plan_id.eq.wholesaler-monthly,plan_id.eq.wholesaler-annual,plan_id.eq.wholesaler-&-cash-buyer-annual,plan_id.eq.wholesaler-&-cash-buyer-monthly"
    );

  if (wsCountErr) {
    console.log(wsCountErr);
  }

  return {
    props: { wsCount: wsCount, cbCount: cbCount },
    revalidate: 3600,
  };
};

const HomePage = ({ wsCount, cbCount }) => {
  const router = useRouter();

  const toast = createStandaloneToast();

  const [isMobile] = useMediaQuery("( max-width: 420px )");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (
      router?.asPath?.includes("#access_token=") &&
      router?.asPath?.includes("refresh_token=") &&
      router?.asPath?.includes("token_type=bearer")
    ) {
      if (router?.asPath?.includes("type=recovery")) {
        onOpen();
      } else if (router?.asPath?.includes("type=signup")) {
        toast({
          title: "Account verification successful!",
          description:
            "Log in using the credentials provided when you signed up.",
          status: "success",
          duration: 9000,
          isClosable: true,
          id: "toast-success",
        });

        router.push("/auth/login");
      }
    }
  }, []);

  return (
    <>
      <Flex direction="column" justify="center" pt="10vh">
        <Flex
          direction={["column", null, "column", "column", "row"]}
          w="100%"
          h={[null, null, null, null, "90vh"]}
          mb={["4vh", 0]}
        >
          <VStack
            w={["100%", null, "100%", "100%", "50%"]}
            justify="center"
            align="flex-start"
            p="0 5vw"
            spacing={6}
          >
            <Heading
              as="h1"
              fontSize={["48px", null, "56px", "86px", "72px"]}
              color="navyBlue"
              pt={["1vh", null, "1vh", "1vh", 0]}
              pb={["1vh", null, "2vh", "2vh", "4vh"]}
            >
              Linking
              <br />
              Cash Buyers
              <br />
              To Wholesalers
            </Heading>
            <Text fontSize={["20px", null, "24px", "32px", "20px"]}>
              Join our ever-growing database of Cash Buyers & Wholesalers to
              ensure you always have the real estate connections you need. Match
              with buyers and/or sellers within your price range and area of
              operation.
            </Text>
            <Text
              fontWeight="bold"
              fontSize={["26px", null, "30px", "38px", "26px"]}
            >
              Made by investors, for investors.
            </Text>
          </VStack>
          <Flex
            direction="column"
            w={["100%", null, "80%", "80%", "50%"]}
            h={["auto", null, "100%", "100%", "auto"]}
            justify="center"
            p="0 2vw"
            m={[0, null, "5vh auto 7.5vh auto", null, 0]}
          >
            <NextImage
              src="/images/rei-link-states-map.svg"
              width={863}
              height={564}
              priority={true}
              className={styles.landing_page_main_img}
            />
            <HStack w="100%" justify="space-around">
              <VStack spacing={0}>
                <Heading size="md" color="blue.base">
                  {wsCount}
                </Heading>
                <Text size="lg" color="navyBlue">
                  Wholesalers
                </Text>
              </VStack>
              <VStack spacing={0}>
                <Heading size="md" color="green.base">
                  {cbCount}
                </Heading>
                <Text size="lg" color="navyBlue">
                  Cash Buyers
                </Text>
              </VStack>
            </HStack>
          </Flex>
        </Flex>
        <Flex
          direction={["column", null, "column", "column", "row"]}
          w={["92.5%", null, "92.5%", "92.5%", "95%"]}
          m={[
            "0 auto 7.5vh auto",
            null,
            "0 auto",
            "0 auto",
            "0 auto 7.5vh auto",
          ]}
          justify="space-evenly"
          alignItems="center"
        >
          <PlanAdCard
            planName="Cash Buyer"
            primaryColor="blue.base"
            hoverColor="#0ca8da"
            listItems={[
              "Search & View Contracts",
              "Match with Wholesalers",
              "Realtime Chat",
              "Interactive Map",
            ]}
          />
          <PlanAdCard
            planName="Wholesaler"
            primaryColor="green.base"
            hoverColor="#45a375"
            listItems={[
              "Post Contracts",
              "Match with Cash Buyers",
              "Realtime Chat",
              "Interactive Map",
            ]}
          />
          <PlanAdCard
            planName="Both"
            primaryColor="orange.base"
            hoverColor="orange.500"
            listItems={[
              "Post, Search, & View Contracts",
              "Match with Both Parties",
              "Realtime Chat",
              "Interactive Map",
            ]}
          />
        </Flex>
        <VStack
          w="100%"
          justify="space-between"
          alignItems="center"
          m="0 auto 7.5vh auto"
        >
          <Box
            display={["none", null, "none", "none", "initial"]}
            left={65}
            w="100%"
            position="absolute"
            zIndex={-1}
          >
            <Box m="0 0 0 auto" position="relative" w="246px" h="225px">
              <NextImage
                src="/images/rei-link-triangles-1.png"
                layout="fill"
                objectFit="cover"
                priority={true}
              />
            </Box>
          </Box>
          <Heading textAlign={["center", "initial"]} as="h3" mb="5vh" size="lg">
            Your Path to REI Success
          </Heading>
          <Flex
            w={["85vw", null, "95vw", null, "85vw"]}
            justify="center"
            align="center"
          >
            {!isMobile ? (
              <SignUpStepsHorizontalDynamic />
            ) : (
              <SignUpStepsVerticalDynamic />
            )}
          </Flex>
          <Box
            w="100%"
            mb="5vh"
            zIndex={-1}
            display={["none", null, "none", "none", "initial"]}
          >
            <Box position="absolute" left={-75}>
              <NextImage
                src="/images/rei-link-triangles-2.png"
                width={268}
                height={216}
                priority={true}
              />
            </Box>
          </Box>
        </VStack>
        <VStack w="85vw" m="0 auto 7.5vh auto">
          <Heading
            m={["auto", null, 0]}
            as="h3"
            alignSelf="flex-start"
            size="lg"
          >
            Features
          </Heading>
          <FeaturesBlock
            imageSrc="interactive-map"
            trianglesNum={3}
            trianglesDimensions={[478.5, 504]}
            headingText="Detailed Map"
          >
            <Text size="md">
              <chakra.strong>Utilize an interactive map</chakra.strong> to
              visualize wholesaler contracts across the nation.
            </Text>
          </FeaturesBlock>
          <FeaturesBlock
            imageSrc="realtime-chat"
            trianglesNum={4}
            trianglesDimensions={[425, 430]}
            headingText="Realtime Chat"
            reverse
          >
            <Text size="md">
              <chakra.strong>Interact with other users</chakra.strong> via a
              full-featured realtime chat client.
            </Text>
          </FeaturesBlock>
          <FeaturesBlock
            imageSrc="simple-interface"
            trianglesNum={5}
            trianglesDimensions={[348, 542]}
            headingText="Simple Interface"
          >
            <Text size="md">
              A <chakra.strong>beautifully designed interface</chakra.strong>{" "}
              ensures an exceptional experience.
            </Text>
          </FeaturesBlock>
          <FeaturesBlock
            imageSrc="blogs"
            trianglesNum={6}
            trianglesDimensions={[487, 548]}
            headingText="Blogs"
            reverse
          >
            <Text size="md">
              Expert insight provided by REI pro & SEO wizard{" "}
              <chakra.strong>Jerryl Noorden</chakra.strong>.
            </Text>
          </FeaturesBlock>
        </VStack>
      </Flex>
      {router?.asPath?.includes("#access_token=") &&
      router?.asPath?.includes("refresh_token=") &&
      router?.asPath?.includes("token_type=bearer") &&
      router?.asPath?.includes("type=recovery") ? (
        <ResetPasswordModalDynamic
          accessObj={router?.asPath?.split("#")[1]?.split("&")}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default HomePage;
*/

import { Flex, Link, Box, Text, Button, Image,  } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import HomepageCarousel from "src/components/layout/carousel/HomepageCarousel.js";
import AltMap from "src/components/alternativeMap/AltMap";
import Clock from "src/components/countdownClock/CountdownClock.js";
import CirclePerimeter from "src/components/iconsOnThePerimeter/CirclePerimeter.js";

const AltIndex = () => {
  const [renderCarousel, setRenderCarousel] = useState(false);
  useEffect(() => {
    if (typeof window !== undefined) {
      setRenderCarousel(true);
    }
  });
  return (
    <Flex
      flexDirection="column"
      maxWidth="100%"
      justifyContent="center"
      paddingTop="15vh"
      alignItems="center"
    >
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Flex alignItems="space-between" justifyContent="center">
          <Flex
            flexDirection="column"
            width="30%"
            justifyContent="flex-start"
            alignItems="space-between"
            margin={20}
          >
            <Text fontSize="4xl" as="h1">
              We All Have One Thing In Common...
            </Text>
            <Text
              fontSize={["26px", null, "30px", "38px", "26px"]}
              textAlign="right"
            >
              We all want to make money!
            </Text>
            <Text>
              You, can't do this alone. You, need cash buyers, discounted
              contracts, wholesalers, money lenders, REI-friendly closing
              attorneys, real estate agents, contractors and more, to become
              successful. We are that missing link to your REI success. What are
              you waiting for...
            </Text>
            <Flex justifyContent="center" m={5}>
              <Button
                variant="solid"
                size="md"
                className="btn-border-radius"
                backgroundColor="rgb(255, 96, 34)"
                color="white"
              >
                Link-Up
              </Button>
              <Button
                variant="solid"
                size="md"
                ml={10}
                className="btn-border-radius"
                border="1px"
              >
                Learn More
              </Button>
            </Flex>
          </Flex>
          <Flex
            height="30vh"
            width="30vw"
            ml={20}
            alignItems="space-between"
            mt={20}
            mb={20}
            mr={20}
          >
            <Box
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              minWidth="539px"
              minHeight="314px"
              backgroundColor="blue"
            ></Box>
          </Flex>
        </Flex>
        {/* Type/counter for each User Block  */}
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          maxW="50%"
        >
          <Flex m={3} p={5} flexDirection="column" alignItems="center">
            <Text fontSize="32px">1245</Text>
            <Text>Wholesalers</Text>
          </Flex>
          <Flex
            mb={5}
            ml={3}
            mr={3}
            p={5}
            flexDirection="column"
            alignItems="center"
          >
            <Text fontSize="36px">1245</Text>
            <Text alignItems="center">
              Discounted Contracts
              <br /> Up For Grabs!
            </Text>
          </Flex>
          <Flex m={3} p={5} flexDirection="column" alignItems="center">
            <Text fontSize="32px">1245</Text>
            <Text>Cash Buyers</Text>
          </Flex>
        </Flex>
        <Flex>
          {/* Map of the USA With Counters */}
          <Flex
            alignItems="center"
            justifyContent="center"
            minW="400px"
            width="60vw"
            maxW="80vw"
            alignItems="center"
          >
            <Box
              w="100%"
              h="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <AltMap />
            </Box>
          </Flex>
          {/* Map of the USA With Counters */}
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Flex p={2} flexDirection="column" alignItems="center">
              <Text fontSize="20px">1245</Text>
              <Text>Money Lenders</Text>
            </Flex>
            <Flex p={2} flexDirection="column" alignItems="center">
              <Text fontSize="20px">1245</Text>
              <Text>Agents</Text>
            </Flex>
            <Flex p={2} flexDirection="column" alignItems="center">
              <Text fontSize="20px">1245</Text>
              <Text>Attorneys</Text>
            </Flex>
            <Flex p={2} flexDirection="column" alignItems="center">
              <Text fontSize="20px">1245</Text>
              <Text>Contractors</Text>
            </Flex>
            <Flex p={2} flexDirection="column" alignItems="center">
              <Text fontSize="20px">1245</Text>
              <Text>FSBO</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* Carousel Block */}
      <Flex flexDirection="column" mt={10}>
        <Flex flexDirection="column">
          <Flex flexDirection="column">
            <Text textAlign="center">
              We Set Out To Create A Platform To Bring Cash Buyers And
              Wholesalers Together.
            </Text>
            <Text textAlign="center">But It grew Into SO Much More...</Text>
          </Flex>
          <Flex textAlign="center">
            {/* Need to figure out implementation for Carousel */}
            {renderCarousel ? (
              <Box minHeight="70vh">
                <HomepageCarousel />
              </Box>
            ) : null}
            {/* <Box    
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    alignItems="right"
                    flexDirection="column"
                    minWidth="539px"
                    minHeight="314px"
                    backgroundColor='blue'
                    m={5}
                  >  
                  </Box> 
                <Box    
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    minWidth="539px"
                    minHeight="314px"
                    backgroundColor='blue'
                    m={5}
                  >  
                  </Box>  
                <Box    
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    alignItems="left"
                    flexDirection="column"
                    minWidth="539px"
                    minHeight="314px"
                    backgroundColor='blue'
                    m={5}
                  >  
                  </Box>          */}
          </Flex>
        </Flex>

        <Flex justifyContent="center">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
            mt={10}
            mb={10}
          >
            <Box
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              minWidth="539px"
              minHeight="314px"
              backgroundColor="blue"
            >
              <Text color="white"> VIDEO</Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      {/* Carousel Block */}

      {/* CountDown Block */}
        <Clock/>
      {/* CountDown Block */}

      <Flex flexDirection="column">
        <Flex flexDirection="column">
          <Text textAlign="center" mb={5}>
            Everyone You Need In One Place And One Click!
          </Text>
        </Flex>
        <Flex justifyContent="center" alignItems="stretch">
          <Flex flexDirection="column" mr={8}>
            <Text
              fontWeight="bold"
              textAlign="center"
              letterSpacing="wide"
            ></Text>
            <Box
              textAlign="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              minWidth="539px"
              minHeight="314px"
              backgroundColor="blue"
            >
              {/* https://stackoverflow.com/questions/12813573/position-icons-into-circle  -  link for making icons displayed in circle.*/}
              <Text color="white"> 8 Icons around a REI LINK Image</Text>
            </Box>
          </Flex>
        </Flex>
             <Box>   
        <CirclePerimeter/>
                  </Box>
        <Box boxSize="sm">
          <Image src="public/images/wholesaler_icon.png'" alt="wholesaler_icon" />
                  
        </Box>
      </Flex>
        
      <Flex minH="30vh"></Flex>
    </Flex>
  );
};
export default AltIndex;
